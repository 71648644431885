import axios from 'axios'
import Vue from 'vue'

import RegistrationEmailSuggestion from 'Components/components/RegistrationEmailSuggestion.vue'

import { ValidateFieldDirective, ValidateMixin } from 'Components/js/utils/validation'
import { WButton, WCheckbox, WInput, WSelect, WRecaptcha, WModal } from 'Utils/ui'

import 'Assets/scss/registration.scss'

Vue.prototype.Url = window.Url

const REGEXP_PHONE = new RegExp(/^(\+7|7|8)?[\s\-]?\(?[7][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/)
const REGEXP_EMAIL = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

const PROFILE_TYPE = {
    Resident: 1,
    Individual: 3
}

export default {
    name: 'user-data',
    props: {
        type: {
            type: String,
            default: 'Any',
            validator: (value) => {
                const types = [ 'Email', 'Phone', 'Any' ]

                if (types.indexOf(value) === -1) {
                    console.error(`[Vue warn]: prop "type" must be any of following "${types.join(', ')}"`)
                    return false
                }

                return true
            }
        },
        inviteCode: String,
        inviteTag: String,
        showRecaptcha: Boolean,
        specialityId: Number,
        hideSpecialitySelector: Boolean,
        personalProcessingAccepted: Boolean,
        generalSpecialityAbTestEnabled: {
            type: Boolean,
            default: true
        },
        specialities: {
            type: Array,
            default: () => []
        }
    },
    mixins: [ ValidateMixin ],
    directives: {
        'validate-field': ValidateFieldDirective
    },
    components: {
        WButton,
        WCheckbox,
        WInput,
        WRecaptcha,
        WSelect
    },
    computed: {
        registrationDTO () {
            const data = {}
            const requiredResident = [
                'Password',
                'ProfileType',
                'PersonalProcessingAccepted',
                'Accepted',
                'Code',
                'Captcha',
                'SuppressCaptcha',
                'InvitationTag',
                'GeneralSpecialityAbTestEnabled'
            ]
            const requiredIndividual = [
                'INN',
                'OGRNIP',
                'PassportSeries',
                'PassportNumber'
            ]
            const required = requiredResident.concat(requiredIndividual)

            required.forEach((field) => {
                // Copy only filled fields
                if (!this.form[field] && this.form[field] !== false) return undefined
                if (field === 'Captcha' && this.form.SuppressCaptcha) return undefined
                if (this.form.ProfileType !== PROFILE_TYPE.Individual && requiredIndividual.includes(field)) return undefined

                data[field] = this.form[field]
            })

            // Get email/phone
            data[this.identifier.type] = this.identifier.value

            // Get id from select inputs
            data.City = this.getOptionId(this.form.City)
            data.Speciality = this.getOptionId(this.form.Speciality)

            return data
        },
        identifier () {
            const raw = this.form.Login.trim()
            const login = {
                text: 'Телефон или email',
                type: 'Unknown',
                value: raw
            }

            if (REGEXP_EMAIL.test(raw)) {
                login.text = 'Email'
                login.type = 'Email'
            }

            if (REGEXP_PHONE.test(raw)) {
                let phonenumber = raw.replace(/\D/g, '')

                login.text = 'Телефон'
                login.type = 'Phone'
                login.value = phonenumber.length === 11 ? `7${phonenumber.slice(-10)}` : `997${phonenumber.slice(-10)}`
            }

            if (this.type === 'Any') return login

            login.type = this.type

            return login
        },
        isSpecialitySelectorHidden () {
            return this.hideSpecialitySelector && Number.isInteger(this.specialityId)
        },
        loginErrors () {
            const emailRequiredError = 'Укажите e-mail'
            const phoneRequiredError = 'Укажите телефон в верном формате'
            const loginRequiredError = 'Укажите e-mail или номер телефона в верном формате'

            if (this.type === 'Any') {
                const errors = this.validation.getErrors('Email').length
                    ? this.validation.getErrors('Email')
                    : this.validation.getErrors('Phone')

                return errors.map((error) => {
                    return error === emailRequiredError || error === phoneRequiredError
                        ? loginRequiredError
                        : error
                })
            }

            return this.validation.getErrors(this.type)
        },
        passportSeriesAndNumberErrors () {
            const value = this.form.PassportSeriesAndNumber // hack to recalculate values on input
            const seriesErrors = this.validation.getErrors('PassportSeries')
            const numberErrors = this.validation.getErrors('PassportNumber')

            if (this.validation.hasErrors('PassportSeries') || this.validation.hasErrors('PassportNumber')) {
                return seriesErrors.concat(numberErrors)
            }

            return seriesErrors.concat(numberErrors)
        },
        areIndividualFieldsVisible () {
            return this.form.ProfileType === PROFILE_TYPE.Individual
        },
        profileTypeHeaderText () {
            switch (this.form.ProfileType) {
                case PROFILE_TYPE.Individual:
                    return 'Регистрация ИП'
                default:
                    return 'Регистрация'
            }
        },
        profileTypeSwitchButtonText () {
            switch (this.form.ProfileType) {
                case PROFILE_TYPE.Individual:
                    return 'Зарегистрироваться как физ. лицо'
                default:
                    return 'Зарегистрироваться как ИП'
            }
        }
    },
    data () {
        return {
            loading: false,
            rules: {
                $id: 'registration',
                type: 'object',
                required: [ 'City', 'Speciality', 'Password', 'PersonalProcessingAccepted', 'Accepted', 'ProfileType' ],
                properties: {
                    ProfileType: { type: 'integer', enum: [ PROFILE_TYPE.Resident, PROFILE_TYPE.Individual ] },
                    City: { type: 'integer' },
                    Speciality: { type: 'integer' },
                    Email: { type: 'string', format: 'email' },
                    Phone: { type: 'string', pattern: '^[7,8][0-9]{10,12}$' },
                    Password: { type: 'string', minLength: 6 },
                    PersonalProcessingAccepted: { type: 'boolean', enum: [ true ] },
                    Accepted: { type: 'boolean', enum: [ true ] },
                    Captcha: { type: 'string', minLength: 1 },
                    SuppressCaptcha: { type: 'boolean' },
                    GeneralSpecialityAbTestEnabled: { type: 'boolean' },
                    INN: { type: 'string', pattern: '^[0-9]{12}$' },
                    OGRNIP: { type: 'string', pattern: '^[0-9]{15}$' },
                    PassportSeries: { type: 'string', pattern: '^[0-9]{4}$' },
                    PassportNumber: { type: 'string', pattern: '^[0-9]{6}$' }
                },
                anyOf: [
                    { required: [ 'Email' ] },
                    { required: [ 'Phone' ] }
                ],
                allOf: [
                    {
                        if: {
                            properties: {
                                SuppressCaptcha: { const: false }
                            }
                        },
                        then: {
                            required: [ 'Captcha' ]
                        }
                    }, {
                        if: {
                            properties: {
                                ProfileType: { const: PROFILE_TYPE.Individual }
                            }
                        },
                        then: {
                            required: [ 'INN', 'OGRNIP', 'PassportSeries', 'PassportNumber' ]
                        }
                    }
                ],
                errorMessage: {
                    properties: {
                        City: 'Укажите город',
                        Speciality: 'Выберите профессию',
                        Email: 'Укажите e-mail',
                        Phone: 'Укажите телефон в верном формате',
                        Password: 'Пароль должен содержать минимум 6 символов',
                        PersonalProcessingAccepted: 'Согласитесь с условиями',
                        Accepted: 'Согласитесь с условиями',
                        Captcha: 'Неверная капча',
                        INN: 'Укажите корректный ИНН',
                        OGRNIP: 'Укажите корректный ОГРНИП',
                        PassportSeries: 'Укажите серию паспорта',
                        PassportNumber: 'Укажите номер паспорта'
                    }
                }
            },
            form: {
                Type: 'Any',
                ProfileType: PROFILE_TYPE.Resident,
                City: null,
                Speciality: null,
                Login: '',
                Password: '',
                PersonalProcessingAccepted: false,
                Accepted: false,
                Captcha: '',
                Code: null,
                InvitationTag: null,
                SuppressCaptcha: false,
                GeneralSpecialityAbTestEnabled: true,
                INN: '',
                OGRNIP: '',
                PassportSeriesAndNumber: '',
                PassportSeries: '',
                PassportNumber: ''
            },
            remote: {
                cities: []
            }
        }
    },
    methods: {
        getOptionId (val) {
            return val ? val.id : null
        },
        getFormattedLogin (val) {
            const login = val.trim()

            return REGEXP_PHONE.test(login) ? `7${login.replace(/\D/g, '').slice(-10)}` : login
        },
        getFormattedPassportSeriesOrNumber (val) {
            this.setPassportSeriesAndNumber(val)

            return val.length <= 4 ? val : val.substring(4)
        },
        clearLoginErrors () {
            this.validation.setErrors('Email', [])
            this.validation.setErrors('Phone', [])
        },
        clearPassportErrors () {
            this.validation.setErrors('PassportSeries', [])
            this.validation.setErrors('PassportNumber', [])
        },
        setPassportSeriesAndNumber (val) {
            this.form.PassportSeries = val.substring(0, 4)
            this.form.PassportNumber = val.substring(4)

            if (val.length === 10) {
                this.clearPassportErrors()
            }
        },
        setDefaultSpeciality (id) {
            const speciality = this.specialities.find(el => el.id === id) || {}
            const text = speciality.text || 'Профессия'

            this.form.Speciality = { id, text }
        },
        switchProfileType () {
            if (this.form.ProfileType === PROFILE_TYPE.Resident) {
                return this.form.ProfileType = PROFILE_TYPE.Individual
            }

            if (this.form.ProfileType === PROFILE_TYPE.Individual) {
                return this.form.ProfileType = PROFILE_TYPE.Resident
            }
        },
        async onSearchCities (value) {
            this.remote.cities = await this.fetchCities(value)
        },
        async fetchCities (q = '') {
            const params = { q }

            try {
                const url = this.Url.route('ajax', { action: 'cities' })
                const response = await axios(url, { params })
                const { data } = response

                return data.map(el => ({ id: el.id, text: el.title, region: el.regionTitle }))
            } catch (e) {
                return []
            }
        },
        async getYMClientId () {
            return new Promise((resolve, reject) => {
                if (window.ym === undefined) {
                    resolve(null)
                }

                try {
                    ym(90765596, 'getClientID', function (clientID) {
                        resolve(clientID)
                    })
                } catch (err) {
                    resolve(null)
                }
            })
        },
        async register () {
            if (this.loading) return undefined

            try {
                this.loading = true

                await this.validateLogin(this.identifier.type, this.identifier.value)

                const registrationDTO = this.registrationDTO
                const data = new FormData()
                const ymClientId = await this.getYMClientId()

                data.set('YandexMetrikaClientId', ymClientId)

                for (let [k, v] of Object.entries(this.registrationDTO)) {
                    if (k === 'Captcha') {
                        // Remap old captcha to google recaptcha
                        data.set('g-recaptcha-response', v)
                    } else {
                        data.set(k, v)
                    }
                }

                await this.validation.validate(this.rules, registrationDTO)
                await axios({
                    url: Url.route('users.register'),
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'content-type': 'multipart/form-data'
                    },
                    method: 'POST',
                    data
                })

                // TODO: handle 200 OK
                // возвращается только в случае, если регион закрыт на воркл
            } catch (error) {
                const { status, data, headers } = error.response || {}

                if (status === Workle.http.statusCodes.ajaxRedirect) {
                    const options = {
                        redirectLocation: headers.location,
                        specialityId: this.registrationDTO.Speciality,
                        userId: data
                    }

                    if (this.registrationDTO) {
                        options.email = this.registrationDTO.Email
                        options.phoneNumber = this.registrationDTO.Phone
                    }

                    return this.$emit('on-registration-completed', options)
                }

                if (status === Workle.http.statusCodes.badRequest && this.$refs.recaptcha) {
                    this.$refs.recaptcha.reset()
                }

                if (data && data.errors) {
                    Object.keys(data.errors).forEach((key) => {
                        const force = key === 'Phone' || key === 'Email'

                        this.validation.setErrors(key, data.errors[key], force)
                    })
                }
            } finally {
                this.loading = false
            }
        },
        async validateLogin (type, login) {
            if (type !== 'Phone' && type !== 'Email') return Promise.resolve()

            const data = await this.fetchLoginSuggestion(type, login)
            const { qc: qualityCode, email, phone } = data
            const badQualityCodes = [ 1, 2 ]

            if (badQualityCodes.includes(qualityCode)) {
                const message = `Укажите корректный ${type === 'Email' ? 'e-mail' : 'телефон'}`
                const response = { data: {
                    errors: { [ type ]: [ message ] } }
                }

                return Promise.reject({ response })
            }

            if (qualityCode === 4) {
                const suggestion = email || phone

                this.form.Login = await this.approveLoginSuggestion(login, suggestion)

                return Promise.resolve()
            }

            return Promise.resolve()
        },
        async fetchLoginSuggestion (type, value) {
            const key = type.toLowerCase()
            const url = Url.route(`api.dadata.clean.${key}`, { [key]: value })
            const { data } = await axios(url)

            return data
        },
        approveLoginSuggestion (login, suggestion) {
            return new Promise((resolve) => {
                let result = login
                const ModalInstance = Vue.extend(WModal)
                const EmailCorrectInstance = Vue.extend(RegistrationEmailSuggestion)
    
                const modal = new ModalInstance({
                    propsData: { title: 'Изменить', confirmation: true }
                })
    
                const emailCorrectConfirm = new EmailCorrectInstance({
                    propsData: { login, suggestion }
                })
    
                modal.$mount()
                modal.$slots.body = [ emailCorrectConfirm.$mount()._vnode ]
                modal.$on('on-close', () => resolve(result))
                modal.$on('on-confirm', () => {
                    result = suggestion
                })
            })
        }
    },
    async created () {
        if (this.specialityId) this.setDefaultSpeciality(this.specialityId)
        if (this.inviteCode) this.form.Code = this.inviteCode
        if (this.inviteTag) this.form.InvitationTag = this.inviteTag

        this.form.Type = this.type
        this.form.SuppressCaptcha = !this.showRecaptcha
        this.form.PersonalProcessingAccepted = this.personalProcessingAccepted
        this.form.GeneralSpecialityAbTestEnabled = this.generalSpecialityAbTestEnabled
        this.remote.cities = await this.fetchCities()
    },
    template: `
        <form
            class="registration__step"
            @submit.prevent="register"
        >
            <h2 class="registration__step-title">
                {{ profileTypeHeaderText }}
            </h2>
            <div class="registration__item">
                <span v-if="isSpecialitySelectorHidden">
                    выберите город
                </span>
                <span v-else>
                    выберите профессию и город
                </span>
            </div>
            <div
                v-show="!isSpecialitySelectorHidden"
                class="registration__item"
            >
                <w-select
                    v-model="form.Speciality"
                    v-validate-field.lazy.collapse="{
                        rules: rules,
                        transform: getOptionId
                    }"
                    name="Speciality"
                    text="Профессия"
                    :test="form.Speciality"
                    :options="specialities"
                    :errors="validation.getErrors('Speciality')"
                />
            </div>
            <div class="registration__item">
                <w-select
                    v-model="form.City"
                    v-validate-field.lazy.collapse="{
                        rules: rules,
                        transform: getOptionId
                    }"
                    name="City"
                    :text="validation.hasErrors('City') ? validation.getErrors('City')[0] : 'Город'"
                    :debounce-time="1000"
                    :max-height="200"
                    :options="remote.cities"
                    :searchable="true"
                    :errors="validation.getErrors('City')"
                    @on-search="onSearchCities"
                >
                    <template v-slot:option="{ option }">
                        <div class="registration__cities-option">
                            <span v-text="option.text" />
                            <span
                                class="registration__cities-region"
                                v-text="option.region"
                            />
                        </div>
                    </template>
                </w-select>
            </div>
            <div
                v-show="areIndividualFieldsVisible"
                class="registration__group"
            >
                <div class="registration__item">
                    <span>данные организации</span>
                </div>
                <div class="registration__item">
                    <w-input
                        v-model="form.INN"
                        v-validate-field.lazy.eager="{ rules: rules }"
                        name="INN"
                        text="ИНН"
                        :mask="{
                            placeholder: ' ',
                            input: '999999999999'
                        }"
                        :errors="validation.getErrors('INN')"
                    />
                </div>
                <div class="registration__item">
                    <w-input
                        v-model="form.OGRNIP"
                        v-validate-field.lazy.eager="{ rules: rules }"
                        name="OGRNIP"
                        text="ОГРНИП"
                        :mask="{
                            placeholder: ' ',
                            input: '999999999999999'
                        }"
                        :errors="validation.getErrors('OGRNIP')"
                    />
                </div>
                <div class="registration__item">
                    <w-input
                        v-model="form.PassportSeriesAndNumber"
                        v-validate-field.lazy.eager="{
                            rules: rules,
                            transform: getFormattedPassportSeriesOrNumber
                        }"
                        text="Серия и номер паспорта"
                        :name="form.PassportSeriesAndNumber.length <= 4 ? 'PassportSeries' : 'PassportNumber'"
                        :mask="{
                            placeholder: ' ',
                            input: '9999 999999',
                            unmasked: true
                        }"
                        :errors="passportSeriesAndNumberErrors"
                    />
                </div>
            </div>
            <div class="registration__item">
                <span v-if="type === 'Any'">
                    введите&nbsp;номер&nbsp;телефона
                    или&nbsp;e&#8209;mail&nbsp;и&nbsp;пароль
                </span>
                <span v-else-if="type === 'Email'">
                    введите e‑mail и&nbsp;пароль
                </span>
                <span v-else-if="type === 'Phone'">
                    введите номер телефона и&nbsp;пароль
                </span>
                <span v-else>
                    введите логин и&nbsp;пароль
                </span>
            </div>
            <div class="registration__item">
                <w-input
                    v-if="type === 'Any'"
                    v-model="form.Login"
                    v-validate-field.lazy.eager="{
                        rules: rules,
                        transform: getFormattedLogin,
                        success: clearLoginErrors
                    }"
                    :name="identifier.type === 'Unknown' ? 'Email' : identifier.type"
                    :text="identifier.text"
                    :errors="loginErrors"
                />
                <w-input
                    v-else
                    v-model="form.Login"
                    v-validate-field.lazy.eager="{
                        rules: rules,
                        transform: getFormattedLogin
                    }"
                    :name="type"
                    :text="type === 'Phone' ? 'Телефон' : 'Email'"
                    :errors="loginErrors"
                />
            </div>
            <div class="registration__item">
                <w-input
                    v-model="form.Password"
                    v-validate-field.lazy.eager="{ rules: rules }"
                    name="Password"
                    type="password"
                    text="Пароль"
                    :errors="validation.getErrors('Password')"
                />
            </div>
            <div class="registration__item registration__item--pb-xs registration__item--left">
                <w-checkbox
                    v-model="form.Accepted"
                    v-validate-field.change="{ rules: rules }"
                    name="Accepted"
                    :errors="validation.getErrors('Accepted')"
                >
                    С условиями 
                    <a
                        href="/resources/doc/offer.htm"
                        class="registration__link registration__link--in-text"
                        target="_blank"
                        v-text="'публичной оферты'"
                    /> 
                    согласен
                </w-checkbox>
            </div>
            <div
                :class="{
                    'registration__item': true,
                    'registration__item--left': true,
                    'registration__item--pb-xl': form.SuppressCaptcha
                }"
            >
                <w-checkbox
                    v-model="form.PersonalProcessingAccepted"
                    v-validate-field.input="{ rules: rules }"
                    name="PersonalProcessingAccepted"
                    :errors="validation.getErrors('PersonalProcessingAccepted')"
                >
                    С условиями 
                    <a 
                        href="/resources/doc/personal-data-processing-policy.htm"
                        class="registration__link registration__link--in-text"
                        target="_blank"
                        v-html="'политики по обработке персональных&nbsp;данных'"
                    /> 
                    согласен
                </w-checkbox>
            </div>
            <div
                v-if="!form.SuppressCaptcha"
                class="registration__item registration__item--pb-xl"
            >
                <w-recaptcha
                    ref="recaptcha"
                    v-model="form.Captcha"
                    v-validate-field.input="{ rules: rules }"
                    name="Captcha"
                    :errors="validation.getErrors('Captcha')"
                />
            </div>
            <div class="registration__item registration__item">
                <w-button
                    wide
                    :loading="loading"
                    @click.prevent="register"
                >
                    Зарегистрироваться
                </w-button>
            </div>
            <div class="auth__warning">
                Для успешной регистрации на сайте отключите расширения, блокирующие рекламу
            </div>
        </form>
    `
}
