import Vue from 'vue'
import VueCookies from 'vue-cookies'

import FormUserData from 'Components/js/registration/form.user-data'
import FormEmailValidation from 'Components/js/registration/form.email-validation'
import FormEmailChange from 'Components/js/registration/form.email-change'
import FormEmailSuccess from 'Components/js/registration/form.email-success'
import FormPhoneValidation from 'Components/js/registration/form.phone-validation'
import FormPhoneChange from 'Components/js/registration/form.phone-change'
import FormPhoneSuccess from 'Components/js/registration/form.phone-success'

import 'Assets/scss/registration.scss'

Vue.prototype.Url = window.Url
Vue.use(VueCookies)

// Q_Q never ever do this again, looks terrible
window.RegistrationForm = Vue.component('registration-form', {
    props: {
        inviteCode: String,
        inviteTag: String,
        allowedLogins: {
            type: String,
            default: 'Any'
        },
        isProlanding: {
            type: Boolean,
            default: false
        },
        isMobileRegistration: {
            type: Boolean,
            default: false
        },
        generalSpecialityAbTestTarget: {
            type: Boolean,
            default: false
        },
        personalProcessingAccepted: {
            type: Boolean,
            default: false
        },
        withSpecialitySelector: {
            type: Boolean,
            default: true
        },
        specialityId: Number,
        specialities: {
            type: Array,
            default: [
                { id: 10, text: 'Туризм' },
                { id: 2, text: 'Банковское дело' },
                { id: 5, text: 'Страхование' }
            ]
        },
        suppressCaptcha: {
            type: Boolean,
            default: false
        },
        isEmbedded: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hideSpecialitySelector () {
            return this.generalSpecialityAbTestTarget
                || this.isProlanding
                || !this.withSpecialitySelector
        },
        selectedSpecialityId () {
            return !this.generalSpecialityAbTestTarget
                ? this.specialityId
                : 2
        }
    },
    data () {
        return {
            step: 'REGISTRATION',
            steps: Object.freeze({
                REGISTRATION: FormUserData,
                EMAIL_VALIDATION: FormEmailValidation,
                EMAIL_CHANGE: FormEmailChange,
                EMAIL_SUCCESS: FormEmailSuccess,
                PHONE_VALIDATION: FormPhoneValidation,
                PHONE_CHANGE: FormPhoneChange,
                PHONE_SUCCESS: FormPhoneSuccess
            }),
            registration: {
                email: null,
                phoneNumber: null,
                redirectLocation: null
            }
        }
    },
    methods: {
        // TODO: fix social registration

        // socialRegister (e, network) {
        //     var that = this;

        //     var provider = window.open(
        //         '/social/' + network + '?script_mode=true',
        //         'wrx_register_provider',
        //         'width=780,height=480,location=0,menubar=0,status=0,toolbar=0'
        //     );

        //     var poller = window.setInterval(function () {
        //         try {
        //             if (provider.closed) {
        //                 window.clearInterval(poller);
        //                 return;
        //             }

        //             if (typeof provider.provide_data !== 'undefined') {
        //                 window.clearInterval(poller);
        //                 var data = provider.provide_data();

        //                 if (data && data.close) {
        //                     provider.close();
        //                 } else {
        //                     window.setTimeout(function () {
        //                         provider.close();
        //                     }, 2000);
        //                 }

        //                 if (!suppressSocialRegistration) that.processExternalData(form, network, data);
        //             }
        //         } catch (e) { /* ignore crossdomain errors. */ }
        //     }, 1000);
            
        //     return false;
        // },
        // processExternalData (form, provider, data) {
        //     var that = this;
        //     if (data) {
        //         if (data.success) {
        //             if (form) {
        //                 that.fillProvidedFields(form, provider, data.model);
        //             } else {
        //                 that.fillProvidedFields(form, provider, data.model);
        //             }
        //         } else {
        //             Workle.functions.safe(data.handler).call(window, window);
        //         }
        //     }
        // },
        // fillProvidedFields () {
        //     // TODO: fill model with external service data
        // },
        finishRegistration (url) {
            window.location.href = url
        },
        // TODO: rewrite
        onValidationCompleted () {
            this.step = this.step === 'EMAIL_VALIDATION'
                ? 'EMAIL_SUCCESS'
                : 'PHONE_SUCCESS'

            setTimeout(() => {
                const location = this.registration.redirectLocation

                this.finishRegistration(location)
            }, 3000)
        },
        // TODO: rewrite
        onChangeEmailCancellation () {
            this.step = 'EMAIL_VALIDATION'
        },
        onChangeEmailCompleted (value) {
            this.registration.email = value
            this.step = 'EMAIL_VALIDATION'
        },
        onChangeRegistrationEmail () {
            this.step = 'EMAIL_CHANGE'
        },
        onChangePhoneCancellation () {
            this.step = 'PHONE_VALIDATION'
        },
        onChangePhoneCompleted (value) {
            this.registration.phoneNumber = value
            this.step = 'PHONE_VALIDATION'
        },
        onChangeRegistrationPhone () {
            this.step = 'PHONE_CHANGE'
        },
        onChangeRegistrationCredentials () {
            this.step = 'REGISTRATION'
        },
        async onRegistrationCompleted (options) {
            // Set registration settings
            const { userId, specialityId, email, phoneNumber, redirectLocation } = options

            // TODO: rewrite
            this.registration.email = email || null
            this.registration.phoneNumber = phoneNumber || null
            this.registration.redirectLocation = redirectLocation || null

            try {
                // Trigger "registration" GA event
                Workle.registration.handleCounters(userId)
            } catch (e) {
                // ...
            }

            await this.setGACookies(userId, specialityId, phoneNumber, this.isMobileRegistration)

            return this.registration.phoneNumber === null
                ? this.step = 'EMAIL_VALIDATION'
                : this.step = 'PHONE_VALIDATION'
        },
        async setGACookies (userId, specialityId, phoneNumber, isMobileRegistration = false) {
            const credentialType = phoneNumber === null ? 'email' : 'phone'
            const firstSessionCookie = this.$cookies.get('FirstSession')
            const firstSessionCookieDecoded = firstSessionCookie ? decodeURIComponent(firstSessionCookie) : ''
            const firstSessionHasRegistration = firstSessionCookieDecoded.match(/\&registration=/) ? true : false
            const currentPage = location.host + location.pathname.replace(/\/$/, '')

            return new Promise((resolve) => {
                this.$cookies.set('ga_speciality', specialityId, '7d', '/')
                this.$cookies.set('ga_userId_activation', userId, '7d', '/')
                this.$cookies.set('ga_credential_type', credentialType, '7d', '/')

                if (!firstSessionHasRegistration) {
                    const firstSessionUpdated = `${firstSessionCookie}&registration=${currentPage}`

                    this.$cookies.set('FirstSession', firstSessionUpdated, '182d', '/')
                }

                if (isMobileRegistration) {
                    this.$cookies.set('from_mobile', 'true', '7d', '/')
                }

                resolve()
            })
        }
    },
    template: `
        <div
            :class="{
                'registration': true,
                'registration--embedded': isEmbedded
            }"
        >
            <keep-alive>
                <component
                    v-if="step === 'REGISTRATION'"
                    class="registration__form"
                    :type="allowedLogins"
                    :hide-speciality-selector="hideSpecialitySelector"
                    :personal-processing-accepted="personalProcessingAccepted"
                    :speciality-id="selectedSpecialityId"
                    :specialities="specialities"
                    :show-recaptcha="!suppressCaptcha"
                    :invite-code="inviteCode"
                    :invite-tag="inviteTag"
                    :is="steps['REGISTRATION']"
                    @on-registration-completed="onRegistrationCompleted"
                />
                <component
                    v-else-if="step === 'EMAIL_VALIDATION'"
                    class="registration__form"
                    :email="registration.email"
                    :send-code-immediatly="false"
                    :allow-credential-changing="allowedLogins === 'Any'"
                    :is="steps['EMAIL_VALIDATION']"
                    @on-change-email="onChangeRegistrationEmail"
                    @on-change-credentials="onChangeRegistrationCredentials"
                    @on-validation-completed="onValidationCompleted"
                />
                <component
                    v-else-if="step === 'EMAIL_CHANGE'"
                    class="registration__form"
                    :allow-credential-changing="allowedLogins === 'Any'"
                    :is="steps['EMAIL_CHANGE']"
                    @on-email-changed="onChangeEmailCompleted"
                    @on-cancel="onChangeEmailCancellation"
                />
                <component
                    v-else-if="step === 'EMAIL_SUCCESS'"
                    class="registration__form"
                    :is="steps['EMAIL_SUCCESS']"
                />
                <component
                    v-else-if="step === 'PHONE_VALIDATION'"
                    class="registration__form"
                    :phone-number="registration.phoneNumber"
                    :allow-credential-changing="allowedLogins === 'Any'"
                    :is="steps['PHONE_VALIDATION']"
                    @on-change-phone="onChangeRegistrationPhone"
                    @on-change-credentials="onChangeRegistrationCredentials"
                    @on-validation-completed="onValidationCompleted"
                />
                <component
                    v-else-if="step === 'PHONE_CHANGE'"
                    class="registration__form"
                    :allow-credential-changing="allowedLogins === 'Any'"
                    :is="steps['PHONE_CHANGE']"
                    @on-phone-changed="onChangePhoneCompleted"
                    @on-cancel="onChangePhoneCancellation"
                />
                <component
                    v-else-if="step === 'PHONE_SUCCESS'"
                    class="registration__form"
                    :is="steps['PHONE_SUCCESS']"
                />
            </keep-alive>
        </div>
    `
})
