import axios from 'axios'

import { ValidateMixin, ValidateFieldDirective } from 'Components/js/utils/validation'
import { WButton, WInput, WRecaptchaInvisible } from 'Utils/ui'

import ClockIcon from '!!svg-inline-loader!Components/img/clock.svg'

export default {
    name: 'email-validation',
    props: {
        code: String,
        email: String,
        webmailAddress: String,
        webmailName: String,
        validateCodeImmediatly: {
            type: Boolean,
            default: false
        },
        sendCodeImmediatly: {
            type: Boolean,
            default: true
        },
        allowCredentialChanging: {
            type: Boolean,
            default: true
        },
        passwordRestoring: {
            type: Boolean,
            default: false
        }
    },
    mixins: [ ValidateMixin ],
    directives: {
        'validate-field': ValidateFieldDirective
    },
    components: {
        WRecaptchaInvisible,
        WButton,
        WInput
    },
    filters: {
        timerSeconds (val) {
            return `0:${String(val).padStart(2, '0')}`
        }
    },
    computed: {
        validationDTO () {
            const data = {}
            const required = [
                'Email',
                'Code'
            ]

            required.forEach((field) => {
                // Copy only filled fields
                if (!this.form[field] && this.form[field] !== false) return undefined

                data[field] = this.form[field].trim()
            })

            return data
        }
    },
    data () {
        return {
            letterSendLoading: false,
            codeValidateLoading: false,
            canResendLetter: true,
            letterResendTimer: null,
            letterResendSeconds: 0,
            rules: {
                $id: 'email_validation',
                type: 'object',
                required: [ 'Code' ],
                properties: {
                    Email: { type: 'string', format: 'email' },
                    Code: { type: 'string', minLength: 6 }
                },
                errorMessage: {
                    properties: {
                        Email: 'Укажите корректный e-mail',
                        Code: 'Введите корректный код'
                    }
                }
            },
            form: {
                Email: '',
                Code: ''
            },
            icons: {
                clock: ClockIcon
            }
        }
    },
    methods: {
        navigateToWebmail (url) {
            window.open(url, '_blank')
        },
        setResendTimer () {
            if (this.letterResendTimer !== null) return undefined

            this.canResendLetter = false
            this.letterResendSeconds = 30

            this.letterResendTimer = setInterval(() => {
                if (this.letterResendSeconds === 1) {
                    clearInterval(this.letterResendTimer)

                    this.letterResendTimer = null
                    this.canResendLetter = true
                    this.letterResendSeconds = 0

                    return undefined
                }

                this.letterResendSeconds -= 1
            }, 1000)
        },
        async activationLetterSend () {
            if (this.letterSendLoading || !this.canResendLetter) return undefined

            const data = { Email: this.form.Email }

            this.letterSendLoading = true

            try {
                await axios({
                    url: Url.route('send.link.activation'),
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    method: 'POST',
                    data
                })

                this.setResendTimer()
            } catch (error) {
                // TODO: handle error
            } finally {
                this.letterSendLoading = false
            }
        },
        async restorationLetterSend (recaptcha) {
            if (this.letterSendLoading || !this.canResendLetter) return undefined

            const data = new FormData()

            data.set('g-recaptcha-response-i', recaptcha)
            data.set('Email', this.form.Phone)

            this.letterSendLoading = true

            try {
                await axios({
                    url: Url.route('users.register.restore'),
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    method: 'POST',
                    data
                })

                this.setResendTimer()
            } catch (error) {
                // TODO: catch error
            } finally {
                this.letterSendLoading = false
            }
        },
        async verificationCodeValidate () {
            if (this.codeValidateLoading) return undefined

            const validationDTO = JSON.parse(JSON.stringify(this.validationDTO))
            const data = new FormData();
            const url = this.passwordRestoring
                ? Url.route('users.recover.email.validate')
                : Url.route('users.email.confirmation.validate')

            for (let [k, v] of Object.entries(validationDTO)) {
                data.set(k, v)
            }

            try {
                this.codeValidateLoading = true

                await this.validation.validate(this.rules, validationDTO)
                await axios({
                    url,
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'content-type': 'multipart/form-data'
                    },
                    data
                })

                this.$emit('on-validation-completed', validationDTO.Code)
            } catch (error) {
                const { data } = error.response || {}

                if (data && data.errors) {
                    Object.keys(data.errors).forEach((key) => {
                        this.validation.setErrors(key, data.errors[key])
                    })
                }
            } finally {
                this.codeValidateLoading = false
            }
        }
    },
    async created () {
        this.form.Email = this.email
        this.form.Code = this.code

        if (this.sendCodeImmediatly) {
            this.activationLetterSend()
            this.canResendLetter = false
        }

        if (this.validateCodeImmediatly) {
            this.verificationCodeValidate()
        }

        if (this.passwordRestoring) {
            this.setResendTimer()
        }
    },
    template: `
        <div class="registration__step registration__step--full-height">
            <h2 class="registration__step-title registration__step-title--small">
                <span v-if="!passwordRestoring">
                    Подтверждение <br>электронной почты
                </span>
                <span v-else>
                    Восстановление пароля
                </span>
            </h2>
            <div
                v-if="form.Email"
                class="registration__item registration__item--pb-xl"
            >
                <span>
                    Мы&nbsp;отправили письмо&nbsp;на
                </span>
                <div class="registration__credentials">
                    <span><b>{{ form.Email }}</b></span>
                    <span
                        v-if="!passwordRestoring"
                        class="registration__link"
                        @click="$emit('on-change-email')"
                    >
                        Изменить
                    </span>
                </div>
            </div>
            <div class="registration__item registration__item--pb-lg">
                <w-input
                    v-model="form.Code"
                    v-validate-field.lazy.eager="{ rules: rules }"
                    name="Code"
                    text="Код"
                    :errors="validation.getErrors('Code') || validation.getErrors('Email')"
                />
            </div>
            <div class="registration__item registration__item--pb-sm">
                <w-button
                    wide
                    :loading="codeValidateLoading"
                    @click="verificationCodeValidate()"
                >
                    <span v-if="!passwordRestoring">
                        Активировать аккаунт
                    </span>
                    <span v-else>
                        Продолжить
                    </span>
                </w-button>
                <w-button
                    v-if="webmailAddress"
                    wide
                    outline
                    @click="navigateToWebmail(webmailAddress)"
                >
                    Проверить почту
                </w-button>
            </div>
            <div
                v-if="form.Email"
                class="registration__item registration__item--pb-lg"
            >
                <w-recaptcha-invisible
                    ref="recaptcha-resend"
                    widget-id="recaptcha-resend"
                    @on-success="restorationLetterSend"
                />
                <div
                    v-if="!canResendLetter"
                    class="registration__timer"
                >
                    <p class="registration__timer-text">
                        Отправить письмо повторно через
                    </p>
                    <p class="registration__timer-text">
                        <i class="registration__timer-icon" v-html="icons.clock" />
                        {{ letterResendSeconds | timerSeconds }}
                    </p>
                </div>
                <span
                    v-else
                    class="registration__link registration__link--black"
                    @click="passwordRestoring ? $refs['recaptcha-resend'].execute() : activationLetterSend()"
                >
                    Отправить письмо
                </span>
            </div>
            <div
                v-if="allowCredentialChanging"
                class="registration__item registration__item--bottom registration__item--last"
            >
                <span
                    class="registration__link"
                    @click="$emit('on-change-credentials')"
                >
                    Зарегистрироваться через телефон
                </span>
            </div>
        </div>
    `
}